@use "@angular/material" as mat;
@import "ngx-toastr/toastr";
@import "webflow";

// @include mat-core();

$md-movline-primary: (
  50: #e8f6ef,
  100: #c8e9d8,
  200: #a5dbc0,
  300: #80cea7,
  400: #65c295,
  500: #146161,
  600: #42a877,
  700: #3b9569,
  800: #36845d,
  900: #2e6348,
  // A100 : #ff95e3,
  // A200 : #ff62d5,
  // A400 : #ff2fc8,
  // A700 : #ff15c1,
  contrast:
    (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff
    )
);
$md-movline-secondary: (
  50: #e8f6ef,
  100: #c8e9d8,
  200: #a5dbc0,
  300: #80cea7,
  400: #65c295,
  500: #146161,
  600: #42a877,
  700: #3b9569,
  800: #36845d,
  900: #2e6348,
  // A100 : #ff95e3,
  // A200 : #ff62d5,
  // A400 : #ff2fc8,
  // A700 : #ff15c1,
  contrast:
    (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff
    )
);
$md-movline-accent: (
  50: #e0eaf3,
  100: #b3cbe2,
  200: #80a8cf,
  300: #4d85bb,
  400: #266bad,
  500: #00519e,
  600: #004a96,
  700: #00408c,
  800: #003782,
  900: #002770,
  A100: #9fb8ff,
  A200: #6c92ff,
  A400: #396cff,
  A700: #1f59ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$movline-primary: mat.m2-define-palette($md-movline-primary, 600);
$movline-secondary: mat.m2-define-palette($md-movline-secondary);
$movline-accent: mat.m2-define-palette($md-movline-accent);
// $movline-warn: mat.define-palette($mat-deep-orange);
$movline-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);

// // Define a dark theme
// $dark-theme: mat.define-dark-theme(
//   (
//     color: (
//       primary: mat.define-palette(mat.$green-palette),
//       accent: mat.define-palette(mat.$blue-grey-palette),
//     ),
//     // Only include `typography` and `density` in the default dark theme.
//     typography: mat.define-typography-config(),
//     density: 0,
//   )
// );

// Define a light theme
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($movline-primary),
      secondary: mat.m2-define-palette($movline-secondary),
      accent: mat.m2-define-palette($movline-accent)
    )
  )
);

// Apply the dark theme by default
@include mat.core-color($light-theme);
@include mat.button-color($light-theme);
@include mat.toolbar-theme($light-theme);
@include mat.all-component-themes($light-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($light-theme);
  @include mat.button-color($light-theme);
  @include mat.toolbar-theme($light-theme);
  @include mat.all-component-themes($light-theme);
}

.mat-toolbar.mat-primary {
  color: white;
}

.co-pointer {
  cursor: pointer;
}

.co-simple-list {
  list-style-type: none;
  padding-left: 0;
}

.co-unreadable-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  user-select: none;
}

// tables

.co-table {
  width: 100%;
  table {
    background: #fff;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 1px 1px 2px #ccc;
  }
  thead {
    left: -9999rem;
    position: absolute;
    top: -9999rem;
  }
  td {
    // display: block;
    margin: 0.5rem 0.25rem;
    display: flex;
    justify-content: space-between;
  }
  td:before {
    color: #888;
    content: attr(name) "";
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 300;
    margin-right: 0.5rem;
  }
  tr:nth-last-of-type(even) {
    background: rgba(56, 113, 170, 0.15);
  }

  @media (min-width: 680px) {
    table {
      border-collapse: collapse;
    }
    thead {
      left: 0;
      position: relative;
      top: 0;
    }
    th {
      color: #888;
      font-size: 0.875rem;
      font-weight: 300;
      text-align: left;
    }
    td,
    th {
      display: table-cell;
      padding: 0.75rem 1rem;
      margin: 0;
    }
    td:before {
      content: none;
    }
  }
}

.co-btn-flat-primary {
  background-color: #36845d !important;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 9px 15px;
  text-decoration: none;
}

.co-card {
  background-color: #f8f6f5;
  border: 1px solid #dcdddd;
  border-radius: 20px;
}

.movline-back-button {
  width: 100%;
  letter-spacing: 2px;
  background-color: #8c8c8c;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
  display: block;
}

.field-round {
  border-radius: 10px;
}

.co-buttons-list {

  a {
    margin-right: 8px;
  }
  a:last-child  {
    margin-right: 0px;
  }


  button {
    margin-right: 8px;
  }
  button:last-child  {
    margin-right: 0px;
  }
}
